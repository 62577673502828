import router from "@/router";
import * as firebase from "@/services/firebase";
import * as jwt from "@/services/jwt";
import {
  firestore,
  getAccessToken,
  logOutsetaCustomActivity,
} from "../../services/firebase";
import { notification } from "ant-design-vue";
import { isArguments, update } from "lodash";
import NProgress from "nprogress";
import { toRefs } from "vue";

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    addPreset: firebase.addPreset,
    updatePreset: firebase.updatePreset,
    setDefaultPreset: firebase.setDefaultPreset,
    deletePreset: firebase.deletePreset,
    uploadImage: firebase.uploadImage,
    updateOrg: firebase.updateOrg,
    getOrg: firebase.getOrg,
    removeMember: firebase.removeMember,
    addMember: firebase.addMember,
    resetPassword: firebase.resetPassword,
    saveProfile: firebase.saveProfile,
    updateOrgName: firebase.updateOrgName,
    getAccessToken: firebase.getAccessToken,
    getAllOrgs: firebase.getAllOrgs,
    changeCurrentOrg: firebase.changeCurrentOrg,
    outsetaReport: firebase.logOutsetaCustomActivity,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
};

const DEV = process.env.VUE_APP_AUTHENTICATED
  ? {
      id: "1",
      name: "Long Nguyen",
      email: "long@brivvio.com",
      orgs: [],
      currentOrg: {},
      avatar: "",
      authorized: true,
      accountFetchIsTouched: true,
    }
  : {};

export default {
  namespaced: true,
  state: {
    id: "",
    name: "",
    email: "",
    orgs: [],
    currentOrg: {},
    avatar: "",
    authorized: false,
    loading: false,
    accountFetchIsTouched: false,
    outsetaToken: "",
    ...DEV, // remove it, used for demo build
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      });
    },
    UPDATE_PRESET(state, newPreset) {
      const index = state.currentOrg.presets.findIndex(
        (preset) => preset.id === newPreset.id
      );
      if (index >= 0) {
        Object.assign(state.currentOrg.presets[index], newPreset);
      } else {
        console.log("No matching preset");
      }
    },
    UPDATE_DEFAULT_PRESET(state, payload) {
      state.currentOrg.defaultPreset = payload.defaultPreset;
      state.currentOrg.presets.forEach((preset) => {
        preset.isDefaultPreset = false;
        if (preset.id === state.currentOrg.defaultPreset) {
          preset.isDefaultPreset = true;
        }
      });
    },
    ADD_NEW_PRESET(state, payload) {
      state.currentOrg.presets.push({ ...payload });
    },
    DELETE_PRESET(state, presetId) {
      const index = state.currentOrg.presets.findIndex(
        (preset) => preset.id === presetId
      );
      state.currentOrg.presets.splice(index, 1);
    },
    UPDATE_USER(state, users) {
      state.currentOrg.users.splice(0);
      state.currentOrg.users = users;
    },
    REMOVE_USER(state, userId) {
      const newUsers = state.currentOrg.users.filter(
        (user) => user.id !== userId
      );
      state.currentOrg.users.splice(0);
      state.currentOrg.users = newUsers;
    },
    ADD_USER(state, payload) {
      state.currentOrg.users.push({ ...payload });
    },
    SWITCH_CURRENT_ORG(state, orgId) {
      state.currentOrg = state.orgs.find((org) => org.id === orgId);
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload;
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const login = mapAuthProviders[rootState.settings.authProvider].login;
      login(email, password).then((success) => {
        if (success) {
          dispatch("LOAD_CURRENT_ACCOUNT");
          // const outsetaReport =
          //   mapAuthProviders[rootState.settings.authProvider].outsetaReport;
          // const currentOrg = rootState.user.currentOrg.name;
          // const entityUid = rootState.user.currentOrg.outsetaId;
          // const title =
          //   "New custom activity for account (Logged In From Dashboard) ";
          // const description =
          //   email + " of " + currentOrg + " logged in from Dashboard";
          // outsetaReport(title, description, entityUid);
          notification.success({
            message: "Logged In",
            description: "You have successfully logged in!",
          });
          dispatch("OUTSETA_REPORT", {
            activityType: " logged in from Dashboard",
            email: email,
          });
          NProgress.done();
        }
        // if (!success) {
        //   commit("SET_STATE", {
        //     loading: false,
        //   });
        // }
      });
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name } = payload;
      commit("SET_STATE", {
        loading: true,
      });

      const register =
        mapAuthProviders[rootState.settings.authProvider].register;
      register(email, password, name).then((success) => {
        if (success) {
          dispatch("LOAD_CURRENT_ACCOUNT");
          notification.success({
            message: "Succesful Registered",
            description: "You have successfully registered!",
          });
        }
        if (!success) {
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
    LOAD_CURRENT_ACCOUNT({ commit, dispatch, rootState }) {
      commit("SET_STATE", {
        loading: true,
      });
      const currentAccount =
        mapAuthProviders[rootState.settings.authProvider].currentAccount;
      currentAccount().then((response) => {
        if (response) {
          const { id, email, name, avatar, currentOrg, orgs } = response;
          dispatch("GET_ACCESS_TOKEN", { email: email });
          commit("SET_STATE", {
            id,
            name,
            email,
            avatar,
            currentOrg,
            orgs,
            authorized: true,
          });
        }
        commit("SET_STATE", {
          loading: false,
        });
      });
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout;
      logout().then(() => {
        commit("SET_STATE", {
          id: "",
          name: "",
          role: "",
          email: "",
          avatar: "",
          authorized: false,
          loading: false,
        });
        router.push("/auth/login");
      });
    },
    ADD_PRESET({ commit, rootState }, { newPreset, orgId }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const addPreset =
        mapAuthProviders[rootState.settings.authProvider].addPreset;
      addPreset(newPreset, orgId).then((ref) => {
        if (ref) {
          commit("ADD_NEW_PRESET", {
            ...newPreset,
            id: ref.id,
            key: ref.id,
            isDefaultPreset: false,
          });
          NProgress.done();
          notification.success({
            message: "New Preset Added",
            description: "You have successfully added a new preset!",
          });
        } else {
          notification.warning({
            message: "Oops! Error",
            description: "Failed to add a new preset. Please try again!",
          });
          NProgress.done();
        }
        return true;
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    UPDATE_PRESET({ commit, rootState }, { updatedPreset, orgId, presetId }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const updatePreset =
        mapAuthProviders[rootState.settings.authProvider].updatePreset;
      updatePreset(updatedPreset, orgId, presetId).then((response) => {
        if (response) {
          const newPreset = { id: presetId, key: presetId, ...updatedPreset };
          commit("UPDATE_PRESET", newPreset);
          notification.success({
            message: "Preset Updated",
            description: "You have successfully updated preset!",
          });
          NProgress.done();
        } else {
          notification.warning({
            message: "Oops! Error",
            description: "Failed to update preset. Please try again!",
          });
          NProgress.done();
        }
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    SET_DEFAULT_PRESET({ commit, rootState }, { orgId, presetId }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const setDefaultPreset =
        mapAuthProviders[rootState.settings.authProvider].setDefaultPreset;
      setDefaultPreset(orgId, presetId).then((response) => {
        if (response) {
          commit("UPDATE_DEFAULT_PRESET", {
            defaultPreset: presetId,
          });
          notification.success({
            message: "Default Preset Updated",
            description: "You have successfully updated default preset!",
          });
          NProgress.done();
        } else {
          notification.warning({
            message: "Oops! Error",
            description: "Failed to update default preset. Please try again!",
          });
          NProgress.done();
        }
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    DELETE_PRESET({ commit, rootState }, { orgId, presetId }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const deletePreset =
        mapAuthProviders[rootState.settings.authProvider].deletePreset;
      deletePreset(orgId, presetId).then((response) => {
        if (response) {
          commit("DELETE_PRESET", presetId);
          notification.success({
            message: "Preset Deleted",
            description: "You have successfully deleted preset!",
          });
          NProgress.done();
        } else {
          notification.warning({
            message: "Oops! Error",
            description: "Failed to delete preset. Please try again!",
          });
          NProgress.done();
        }
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    UPLOAD_IMAGE(
      { commit, rootState },
      { imageData, imageName, orgId, isLogo, meta }
    ) {
      const uploadImage =
        mapAuthProviders[rootState.settings.authProvider].uploadImage;
      return uploadImage(imageData, imageName, orgId, isLogo, meta).then(
        (url) => {
          if (url) {
            notification.success({
              message: "Image uploaded",
              description: "Image has been uploaded successfully",
            });
            return url;
          } else {
            notification.warning({
              message: "Failed uploading image",
              description: "Failed to upload image!",
            });
            return null;
          }
        }
      );
    },
    ADD_NEW_MEMBER({ commit, rootState }, { orgId, email, name }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const addMember =
        mapAuthProviders[rootState.settings.authProvider].addMember;
      addMember(orgId, email, name).then((res) => {
        if (res.data.statusCode === 200) {
          commit("ADD_USER", {
            email: email,
            name: name,
            role: 2,
            status: 2,
            id: res.data.userId,
            key: res.data.userId,
          });
          notification.success({
            message: "Succesfully added user to org",
            description: "Login details will be sent to user's email",
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        } else {
          notification.success({
            message: "Oops! Error",
            description:
              "Some errors occurred. Please contact support or try again!",
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
    EDIT_MEMBER({ commit, rootState }, { orgId, uid, name }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const updateOrg =
        mapAuthProviders[rootState.settings.authProvider].updateOrg;

      const currentOrg = rootState.user.currentOrg;
      var users = currentOrg.users;
      const index = users.findIndex((user) => user.id === uid);
      users[index].name = name;
      const newUsers = users.map((user) => {
        return {
          id: user.id,
          email: user.email,
          name: user.name,
          role: user.role,
          status: user.status,
        };
      });
      updateOrg(orgId, { users: newUsers }).then((res) => {
        notification.success({
          message: "Update user successfully",
          description: "User details have been updated successfully",
        });
        NProgress.done();
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    REMOVE_MEMBER({ commit, rootState }, { orgId, uid }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const removeMember =
        mapAuthProviders[rootState.settings.authProvider].removeMember;
      removeMember(orgId, uid).then((res) => {
        if (res.data.statusCode === 200) {
          commit("REMOVE_USER", uid);
          notification.success({
            message: "Remove user successfully",
            description: "Successfully remove user from org",
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        } else {
          notification.warning({
            message: "Oops! Error",
            description: res.data.description,
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
    RESET_PASSWORD({ commit, rootState }, { email }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const resetPassword =
        mapAuthProviders[rootState.settings.authProvider].resetPassword;
      resetPassword(email)
        .then(() => {
          notification.success({
            message: "Reset Password Email Sent",
            description: "Successfully sent a reset password email to " + email,
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        })
        .catch((err) => {
          notification.warning({
            message: "Oops! Error",
            description: err,
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        });
    },
    SAVE_PROFILE({ commit, rootState }, { profile }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const user = rootState.user;
      const saveProfile =
        mapAuthProviders[rootState.settings.authProvider].saveProfile;
      saveProfile(user.id, profile)
        .then(() => {
          commit("SET_STATE", {
            name: profile.displayName,
          });
          notification.success({
            message: "Profile saved!",
            description: "Successfully saved new profile",
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        })
        .catch((err) => {
          notification.warning({
            message: "Oops! Error",
            description: err,
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        });
    },
    UPDATE_ORG_NAME({ commit, rootState }, { name }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const org = rootState.user.currentOrg;
      const updateOrgName =
        mapAuthProviders[rootState.settings.authProvider].updateOrgName;
      updateOrgName(org.id, name)
        .then(() => {
          org.name = name;
          commit("SET_STATE", {
            currentOrg: org,
          });
          notification.success({
            message: "Profile saved!",
            description: "Successfully saved new profile",
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        })
        .catch((err) => {
          notification.warning({
            message: "Oops! Error",
            description: err,
          });
          NProgress.done();
          commit("SET_STATE", {
            loading: false,
          });
        });
    },
    GET_ACCESS_TOKEN({ commit, rootState }, { email }) {
      commit("SET_STATE", {
        loading: true,
      });
      getAccessToken(email).then((response) => {
        const token = response.data.access_token;
        if (token) {
          commit("SET_STATE", {
            outsetaToken: token,
            loading: false,
          });
          if (rootState.user.currentOrg.role === 0) {
            Outseta.setAccessToken(token);
          }
        } else {
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
    UPDATE_STATUS({ commit, rootState }, { id, status }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const updateOrg =
        mapAuthProviders[rootState.settings.authProvider].updateOrg;

      const currentOrg = rootState.user.currentOrg;
      const orgId = currentOrg.id;
      var users = currentOrg.users;
      const index = users.findIndex((user) => user.id === id);
      users[index].status = status;
      const newUsers = users.map((user) => {
        return {
          id: user.id,
          email: user.email,
          name: user.name,
          role: user.role,
          status: user.status,
        };
      });
      updateOrg(orgId, { users: newUsers }).then((res) => {
        NProgress.done();
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    ENABLE_PRESET({ commit, rootState }, { presetId, isActive }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const updatePreset =
        mapAuthProviders[rootState.settings.authProvider].updatePreset;

      const currentOrg = rootState.user.currentOrg;
      const orgId = currentOrg.id;
      const index = currentOrg.presets.findIndex(
        (preset) => preset.id === presetId
      );
      currentOrg.presets[index].isActive = isActive;
      updatePreset({ isActive: isActive }, orgId, presetId).then((res) => {
        NProgress.done();
      });
      commit("SET_STATE", {
        loading: false,
      });
    },
    SWITCH_ORG({ commit, rootState }, { orgId }) {
      commit("SET_STATE", {
        loading: true,
      });
      NProgress.start();
      const getOrg = mapAuthProviders[rootState.settings.authProvider].getOrg;
      getOrg(orgId).then((response) => {
        var newOrgs = rootState.user.orgs;
        newOrgs = newOrgs.map((org) =>
          org.id !== response.id ? org : response
        );
        const changeCurrentOrg =
          mapAuthProviders[rootState.settings.authProvider].changeCurrentOrg;
        changeCurrentOrg(rootState.user.id, orgId).then(() => {
          commit("SET_STATE", {
            orgs: newOrgs,
            currentOrg: response,
            loading: false,
          });
          NProgress.done();
        });
      });
    },
    OUTSETA_REPORT({ rootState }, { activityType, email }) {
      const outsetaReport =
        mapAuthProviders[rootState.settings.authProvider].outsetaReport;
      const currentOrg = rootState.user.currentOrg.name;
      const entityUid = rootState.user.currentOrg.outsetaId;
      const title =
        "New custom activity for account (Logged In From Dashboard) ";
      const description = email + " of " + currentOrg + activityType;
      outsetaReport(title, description, entityUid);
    },
  },
  getters: {
    user: (state) => state,
  },
};
