<template>
  <div :class="$style.topbar">
    <div class="mr-auto">
      <orgs-drop-down-header
        @switchOrg="switchOrg"
        :orgs="orgs"
        :currentOrg="currentOrg"
        :key="currentOrg.id"
      ></orgs-drop-down-header>
    </div>
    <div>
      <button
        class="btn btn-primary px-3"
        style="margin-right: 20px;"
        @click="goToBilling"
        v-if="showUpgradeButton"
      >
        Upgrade
      </button>
    </div>
    <div>
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import VbLanguageSwitcher from "./LanguageSwitcher";
import VbUserMenu from "./UserMenu";
import OrgsDropDownHeader from "../Organisation/Header/OrgsDropDownHeader.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    VbLanguageSwitcher,
    VbUserMenu,
    OrgsDropDownHeader,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const currentOrg = reactive({});
    const orgs = reactive([]);
    const router = useRouter();
    Object.assign(currentOrg, user.value.currentOrg);
    Object.assign(orgs, user.value.orgs);

    watch(user.value, () => {
      Object.assign(currentOrg, user.value.currentOrg);
      Object.assign(orgs, user.value.orgs);
    });

    return { currentOrg, orgs, router, store, user };
  },
  methods: {
    goToBilling() {
      this.router.push({ path: "/billing" });
    },
    showUpgradeButton() {
      if (!this.currentOrg.currentPlan && this.currentOrg.accountStage < 5) {
        return false;
      } else {
        return true;
      }
    },
    switchOrg(orgId) {
      this.store.dispatch("user/SWITCH_ORG", { orgId: orgId });
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
