import { createRouter, createWebHashHistory } from "vue-router";
import NProgress from "nprogress";
import AuthLayout from "@/layouts/Auth";
import MainLayout from "@/layouts/Main";
import store from "@/store";

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
      redirect: "/",
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        {
          name: "Organisation",
          path: "/",
          meta: { title: "Organisation" },
          component: () => import("./views/orgs"),
        },
        {
          name: "Presets",
          path: "/presets",
          meta: { title: "Presets" },
          component: () => import("./views/presets"),
        },
        {
          name: "PresetDetail",
          path: "/presets/:id",
          meta: { title: "Edit Preset" },
          component: () => import("./views/presetDetail"),
          props: true,
        },
        {
          name: "Team",
          path: "/team",
          meta: { title: "Team" },
          component: () => import("./views/team"),
        },
        {
          name: "Billing",
          path: "/billing",
          meta: { title: "Billing" },
          component: () => import("./views/billing"),
        },
        {
          name: "Profile",
          path: "/profile",
          meta: { title: "Profile" },
          component: () => import("./views/profile"),
        },
        // VB:REPLACE-END:ROUTER-CONFIG
      ],
    },
    {
      path: "/verify",
      name: "Verification",
      meta: {
        title: "Success Verification",
      },
      component: () => import("./views/auth/verify"),
    },
    // System Pages
    {
      path: "/auth",
      component: AuthLayout,
      redirect: "auth/login",
      children: [
        {
          path: "/auth/404",
          name: "route404",
          meta: {
            title: "Error 404",
          },
          component: () => import("./views/auth/404"),
        },
        {
          path: "/auth/500",
          meta: {
            title: "Error 500",
          },
          component: () => import("./views/auth/500"),
        },
        {
          path: "/auth/login",
          name: "login",
          meta: {
            title: "Sign In",
          },
          component: () => import("./views/auth/login"),
        },
        {
          path: "/auth/register",
          meta: {
            title: "Sign Up",
          },
          component: () => import("./views/auth/register"),
        },
        {
          path: "/auth/forgot-password",
          meta: {
            title: "Forgot Password",
          },
          component: () => import("./views/auth/forgot-password"),
        },
        {
          path: "/auth/lockscreen",
          meta: {
            title: "Lockscreen",
          },
          component: () => import("./views/auth/lockscreen"),
        },
      ],
    },

    // Redirect to 404
    {
      path: "/:pathMatch(.*)*",
      redirect: { name: "route404" },
    },
  ],
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  setTimeout(() => {
    NProgress.done();
  }, 300);
  if (
    to.matched.some((record) => {
      return record.meta.authRequired;
    })
  ) {
    // console.log("Path " + path);
    // if (path === "/success-verification") {
    //   next();
    // } else {
    if (!store.state.user.authorized) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
    // }
  } else {
    next();
  }
});

export default router;
